import {
  faChevronRight,
  faCircleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoanStatus from '../LoanStatus';
import { LoanCardProps } from '../LoanCard';
import { EnumMap } from '../../../types.ts';
import { LoanDtoStatusEnum } from '../../../openapi/atlantis';
import { cn } from '../../../utils/classUtils.ts';

const variantToClassNameMap: EnumMap<LoanDtoStatusEnum, string> = {
  [LoanDtoStatusEnum.AccountCreated]: 'border-r-[#4967FD]',
  [LoanDtoStatusEnum.ApplicationInProgress]: 'border-r-[#C98B11]',
  [LoanDtoStatusEnum.ApplicationSubmitted]: 'border-r-[#7440CD]',
  [LoanDtoStatusEnum.LoanPreApproved]: 'border-r-[#439775]',
  [LoanDtoStatusEnum.SubmittedToLender]: 'border-r-[#4967FD]',
  [LoanDtoStatusEnum.Underwriting]: 'border-r-[#C98B11]',
  [LoanDtoStatusEnum.ConditionalApproval]: 'border-r-[#C98B11]',
  [LoanDtoStatusEnum.ClearToClose]: 'border-r-[#7440CD]',
  [LoanDtoStatusEnum.Closing]: 'border-r-[#439775]',
  [LoanDtoStatusEnum.Funding]: 'border-r-[#439775]',
  [LoanDtoStatusEnum.Terminated]: 'border-r-[#F84C6C]',
};

const LoanCardGemini: React.FC<LoanCardProps> = ({ loan, onPress }) => {
  return (
    <div
      onClick={() => onPress(loan)}
      className='relative bg-white cursor-pointer rounded-xl p-5 border border-silver'
    >
      <div
        className={cn(
          'absolute top-1/2 left-0 h-1/2 -translate-y-1/2 border-r-4 rounded-r-full',
          variantToClassNameMap[loan.status],
        )}
      />
      <div>
        <div className='flex justify-between items-center'>
          <div className='font-inter-semibold text-xs text-primary-gray font-bold'>
            {loan.loanType}
          </div>
          <div className='font-inter-medium text-sm'>
            <LoanStatus status={loan.status} />
          </div>
        </div>
        <div className='border-b my-2 border-silver' />
        <div className='flex justify-between items-center'>
          {loan.propertyAddress ? (
            <div className='font-inter-medium text-base'>
              {loan.propertyAddress}
            </div>
          ) : (
            <div className='flex flex-row items-center'>
              <FontAwesomeIcon icon={faCircleExclamation} size='lg' />
              <div className='font-inter-light text-base ml-2'>
                The property address has not been entered yet.
              </div>
            </div>
          )}
          <div>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanCardGemini;
